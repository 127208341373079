#PhotographyWrapper {
    margin-top: 80px;
    margin-bottom: 80px;
}

#PhotographyContainer {
    width: 85vw;
    flex-wrap: wrap;
    gap: 15px;

}

.PhotographyImages {
    height: 400px;
    width: auto;
    border-radius: 15px;
}

@media screen and (max-width:750px) {
    .PhotographyImages {
        width: 100%;
        height: auto;
    }

}

#ImageViewer {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.94);
    overflow-y: hidden;
    transition: 0.5s;
}

#ImageViewerImage {
    border-radius: 15px;
    width: clamp(360px, 54%, 500px);
}

